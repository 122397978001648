<template>
  <div class="footer">
    <div class="row">
      <!-- <div class="col-6 col-sm-4 col-md-3">
                <div class="menu-container">
                    <ul class="menu">
                        <li v-for="(item, index) in menuItems" :key="index">
                            <a :href="item.link">{{item.text}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-6 col-sm-12 col-md-3">
                <div class="footer-info">
                    <div class="footer-copyright">
                        <div class="footer-copyrights">
								© <a href="#">Recht einfach</a>. Alle Rechte vorbehalten							</div>
                    </div>
                </div>
            </div> -->
      <div class="disclaimer">
        {{ labels.part_of_system }}<br />
        <a href="https://recht-einfach.schule/agb/" target="_blank">{{
          labels.t_and_c
        }}</a
        ><br />
        <a href="https://recht-einfach.schule/datenschutz/" target="_blank">{{
          labels.privacy
        }}</a
        ><br />
        <a href="https://recht-einfach.schule/impressum/" target="_blank">{{
          labels.inprint
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  props: {
    menuItems: {
      type: Array,
      default: () => [
        { link: "#", text: "test" },
        { link: "#", text: "test2" },
      ],
    },
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
  },
};
</script>

<style lang="scss" scoped>
.footer {
  .disclaimer {
    color: white;
  }
  height: 304px;
  padding: 50px 100px 100px;
  margin-top: auto;
  box-sizing: border-box;
  display: block;
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  background-color: #313131;

  @media screen and (max-width: 1200px) {
    padding: 30px 60px 60px;
  }
  @media screen and (max-width: 1200px) {
    padding: 15px 30px 30px;
  }
  @media screen and (max-with: 992px) {
    padding: 15px 15px 30px;
  }

  a {
    color: #8b8b8b;
    text-decoration: none;
    font-size: 16px;
  }
  .menu-container {
    padding: 0;
    margin: 0;
    .menu {
      margin: 15px 0 0;
      list-style-type: none;
      padding: 0;
      li {
        margin-top: 25px;
        font-weight: 400;
        line-height: initial;
      }
    }
  }
  .footer-copyrights {
    color: #8b8b8b;
    text-decoration: none;
    font-size: 16px;
    text-align: right;
  }
}
</style>
