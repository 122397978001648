<template>
  <div v-if="!accepted" class="wrapper">
    <div class="cookie-container">
      <p>
        Diese Webseite verwendet Cookies und nutzt Dienste von Drittanbietern.
        Bitte stimme der Nutzung von Cookies, sowie der Übermittlung deiner
        personenbezogenen Daten an Drittanbieter in dem in der
        Datenschutzerklärung weiter ausgeführten Umfang
        <a href="/datenschutz">hier</a> zu.
      </p>
      <div class="buttons">
        <button class="button" @click="accept">Zustimmen</button>
      </div>
    </div>
  </div>
</template>

<script>
import c from "@/services/cookie";

export default {
  name: "CookiesFooter",
  data() {
    return {
      accepted: false,
    };
  },
  methods: {
    accept() {
      this.accepted = true;
      c.setCookie("cb", "yes", 99);
    },
  },
  mounted() {
    if (c.getCookie("cb") == "yes") {
      this.accepted = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.wrapper {
  background-color: rgb(108, 65, 226);
  color: rgb(243, 224, 255);
  display: flex;
  z-index: 9999999999999999;
  font-family: "Montserrat", sans-serif;
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px;
  width: 100%;

  .cookie-container {
    display: flex;
    padding: 20px 50px;
    margin: auto;
    box-sizing: border-box;

    @media screen and (min-width: 1200px) {
      font-size: 12px;
    }

    @media screen and (max-width: 767px) {
      padding: 20px 15px;
      margin: auto;
      display: flex;
      flex-direction: column;

      .buttons {
        align-self: flex-end;
      }

      p {
        width: 100% !important;
        a {
          color: $white;
          text-decoration: none;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: 575px) {
        width: 100%;
        margin-left: 0px !important;
        flex-direction: column;
        button {
          margin-left: 0px;
        }
      }
    }

    p {
      width: 75%;
      display: inline-block;
      font-size: 15px;
      line-height: 24px;
      margin-top: 0;
      margin-bottom: 1rem;
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
    }

    button {
      background-color: rgb(53, 31, 111);
      color: rgb(255, 255, 255);
      height: 50px;
      border: 1px solid $white;
      padding: 15px 20px;
      text-transform: uppercase;
      font-size: 15px;
      margin-top: 5px;
      border-radius: 4px;
      margin-left: 15px;
    }
  }

  @media screen and (min-width: 1200px) {
    font-size: 16px;
  }
}
</style>
