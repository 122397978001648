<template>
  <div class="mobile-header-menu">
    <a href="https://recht-einfach.schule/">
      <img
        :src="require('@/assets/logo-mobile-header.svg')"
        alt="Logo"
        class="mobile-header-logo"
      />
    </a>
    <a
      href="#"
      class="share-icon"
      data-toggle="modal"
      data-target="#sharePopup"
    >
      <font-awesome-icon icon="custom-share" class="custom-share" />
    </a>
    <Popup
      class="share-popup"
      modal-id="sharePopup"
      size="xl"
      aria-hidden="false"
      :v-center="true"
    >
      <template v-slot:header>
        <!-- <h5>Share: Recht einfach » <span>{{pageTitle}}</span></h5> -->
        <div class="icon-wrapper">
          <font-awesome-icon
            far
            icon="close-circle-custom"
            class="close-icon"
            data-dismiss="modal"
          />
        </div>
      </template>
      <template v-slot:body>
        <div class="social-media-buttons">
          <a
            :href="`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`"
          >
            <font-awesome-icon icon="facebook" class="facebook icon" /> Facebook
          </a>
          <a
            :href="`https://twitter.com/intent/tweet?url=${shareLink}&text=${pageTitle}`"
          >
            <font-awesome-icon icon="twitter" class="twitter icon" /> Twitter
          </a>
          <a :href="`sms:&body=${shareLink}`">
            <font-awesome-icon icon="sms" class="sms icon" /> Sms
          </a>
          <a :href="`fb-messenger://share/?link=${shareLink}`">
            <font-awesome-icon
              icon="facebook-messenger"
              class="facebook-messenger icon"
            />
            Facebook Messenger
          </a>
          <a :href="`whatsapp://send?text=${shareLink}`">
            <font-awesome-icon icon="whatsapp" class="whatsapp icon" /> WhatsApp
          </a>
          <a :href="`tg://msg_url?url=${shareLink}`">
            <font-awesome-icon icon="telegram-plane" class="telegram icon" />
            Telegram
          </a>
          <a :href="`http://vk.com/share.php?url=${shareLink}`">
            <font-awesome-icon icon="vk" class="vk icon" /> VK
          </a>
          <a
            :href="`https://share.flipboard.com/bookmarklet/popout?v=2&url=${shareLink}&title=${pageTitle}&utm_medium=article-share&utm_campaign=tools&t=1644555892802`"
          >
            <font-awesome-icon icon="flipboard" class="flipboard icon" />
            Flipboard
          </a>
          <a :href="`https://connect.ok.ru/offer?url=${shareLink}`">
            <font-awesome-icon
              icon="odnoklassniki"
              class="odnoklassniki icon"
            />
            Odnoklassniki
          </a>
          <a :href="`http://pinterest.com/pin/create/button/?url=${shareLink}`">
            <font-awesome-icon icon="pinterest" class="pinterest icon" />
            Pinterest
          </a>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import Popup from "@/components/Popups/Popup";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  shareNodes,
  facebook,
  twitter,
  sms,
  facebookMessenger,
  whatApp,
  telegram,
  vk,
  flipboard,
  odnoklassniki,
  pinterest,
  closeCircle,
} from "@/assets/icons";
library.add(
  shareNodes,
  facebook,
  twitter,
  sms,
  facebookMessenger,
  whatApp,
  telegram,
  vk,
  flipboard,
  odnoklassniki,
  pinterest,
  closeCircle
);
export default {
  name: "MobileHeader",
  components: { Popup },
  computed: {
    pageTitle() {
      let currentPath = this.$route.fullPath.split("/");
      let n = currentPath.length;
      return currentPath[n - 1].replace("-", " ");
    },
    shareLink() {
      return `https://recht-einfach.schule/${this.$route.fullPath}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.mobile-header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: $white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 3px 15px -6px #0000002e;

  @media (min-width: 768px) {
    left: 50px;
    width: calc(100% - 50px);
  }

  @media (min-width: 992px) {
    display: none;
  }

  .share-popup {
    opacity: 1;
    background: rgba(0, 0, 0, 0.8);

    ::v-deep .modal-content {
      background-color: transparent;
      color: $white;
      border: 0;
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      outline: 0;

      .modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        padding: 1rem 1rem;
        border: none;

        h5 {
          margin-bottom: 0 !important;
          font-weight: 900;
          font-size: 1.25rem;
          font-family: "Arial", sans-serif;
          span {
            text-transform: capitalize;
          }
        }
        .icon-wrapper {
          border: 1px solid gray;
          border-radius: 50%;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          .close-icon {
            width: 15px;
            height: 15px;
            color: gray;
          }
        }

        .close {
          display: none;
        }
      }

      .modal-body {
        .social-media-buttons {
          display: flex;
          flex-direction: column;
          max-width: 1000px;

          a {
            margin-right: 0;
            color: $white;
            font-size: 18px;
            font-weight: 400;
            font-family: "Arial", sans-serif;

            .icon {
              width: 25px;

              &.facebook {
                color: #465696;
              }

              &.twitter {
                color: #77a8ec;
              }

              &.sms {
                color: #eec126;
              }

              &.facebook-messenger {
                color: #6283fc;
              }

              &.whatsapp {
                color: #78d469;
              }

              &.telegram {
                color: #38afe2;
              }

              &.vk {
                color: #576a90;
              }

              &.flipboard {
                color: #c3322e;
              }

              &.odnoklassniki {
                color: #c27b35;
              }

              &.pinterest {
                color: #bd081c;
              }
            }
          }
        }
      }

      .modal-footer {
        border: none;
      }
    }
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  .mobile-header-logo {
    height: 50px;
    width: auto;
    display: block;
  }
  .share-icon {
    padding-right: 10px;

    .custom-share {
      color: $black;
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
  }
}
</style>
